import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
    Button,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Paper,
    Switch,
    Typography,
} from '@material-ui/core';
import { useStyle } from 'src/pages/user-settings/user-settings-styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TwoFactorFormData } from 'src/pages/user-settings/two-factor/two-factor-form-data';
import {
    useFormControlLabelStyles,
    useInputStyles,
} from 'src/pages/non-users-damage-report/non-users-damage-report-form-styles';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { TwoFactorSettingsDetailsDto } from 'src/pages/user-settings/two-factor/two-factor-settings-details-dto';
import { useSnackbar } from 'notistack';
import { TwoFactorCodeInput } from 'src/pages/user-settings/two-factor/two-factor-code-input';

export const getApiErrors = (response): string[] => {
    const errors = [];
    response?.error?.response?.data?.errors?.twoFactorAuth?.forEach((item) =>
        errors.push(item)
    );
    return errors;
};

export const TwoFactorSection = (): JSX.Element => {
    const classes = useStyle();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();
    const { t } = useTranslation(['user-settings']);
    const httpClient = useHttpClient();
    const [isVerificationCodePending, setIsVerificationCodePending] = useState(false);
    const [isMediumClearable, setIsMediumClearable] = useState(false);
    const [isDeleteVerificationCodePending, setIsDeleteVerificationCodePendingPending] =
        useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [mediumClearCode, setMediumClearCode] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const { reset, setValue, watch, handleSubmit, trigger, getValues } =
        useForm<TwoFactorFormData>({
            mode: 'onChange',
            defaultValues: {
                isPhoneTwoFactorEnabled: false,
                isEmailTwoFactorEnabled: false,
                email: '',
                phoneNumber: '',
            },
        });

    watch(['isPhoneTwoFactorEnabled', 'isEmailTwoFactorEnabled', 'email', 'phoneNumber']);

    const refreshSettings = useCallback(async () => {
        const response = await httpClient.get<TwoFactorSettingsDetailsDto>(
            'two-factor/settings'
        );
        reset(response);
        const isMediumPresent =
            response.isPhoneTwoFactorEnabled || response.isEmailTwoFactorEnabled;
        setIsMediumClearable(isMediumPresent && !response.isVerificationCodePending);
        setIsVerificationCodePending(response.isVerificationCodePending);
        setIsDeleteVerificationCodePendingPending(
            response.isDeleteVerificationCodePending
        );
    }, []);

    const handleChangeValue = useCallback(
        async (e: BaseSyntheticEvent): Promise<void> => {
            setValue(e.target.name, e.target.value);
            await trigger();
        },
        [setValue]
    );

    const onSubmit = async (values: TwoFactorFormData): Promise<void> => {
        try {
            const sentVerificationMail = await httpClient.post<boolean>(
                'two-factor/save',
                values
            );
            if (sentVerificationMail) {
                enqueueSnackbar(t('twoFactor.snackbar.sentMediumVerification'), {
                    variant: 'success',
                });
            }
        } catch (e) {
            const errors = getApiErrors(e);
            if (errors.length > 0) {
                errors.forEach((error) => {
                    enqueueSnackbar(t(`twoFactor.errorResponses.${error}`), {
                        variant: 'error',
                    });
                });
            } else {
                enqueueSnackbar(t('errors:unknownError'), {
                    variant: 'error',
                });
            }
        }

        await refreshSettings();
    };

    const formValues = getValues();

    const verifyMedium = async (): Promise<void> => {
        const isVerified = await httpClient.post<boolean>('two-factor/verify-medium', {
            code: verificationCode,
        });
        if (isVerified) {
            enqueueSnackbar(t('twoFactor.snackbar.mediumVerifySuccess'), {
                variant: 'success',
            });
            setVerificationCode('');
        } else {
            enqueueSnackbar(t('twoFactor.snackbar.mediumVerifyFailed'), {
                variant: 'error',
            });
        }

        await refreshSettings();
    };

    const resendMediumVerification = async (): Promise<void> => {
        await httpClient.post('two-factor/resend-medium-verification');
        await refreshSettings();
        enqueueSnackbar(t('twoFactor.snackbar.sentMediumVerification'), {
            variant: 'success',
        });
    };

    const updateEmailTwoFactor = (isEnabled: boolean): void => {
        if (isEnabled && formValues.isPhoneTwoFactorEnabled) {
            enqueueSnackbar(t('twoFactor.snackbar.emailAndPhoneCantBeEnabled'), {
                variant: 'error',
            });
            return;
        }

        if (isMediumClearable) {
            return;
        }
        setValue('isEmailTwoFactorEnabled', isEnabled);
    };

    const updatePhoneTwoFactor = (isEnabled: boolean): void => {
        if (isEnabled && formValues.isEmailTwoFactorEnabled) {
            enqueueSnackbar(t('twoFactor.snackbar.emailAndPhoneCantBeEnabled'), {
                variant: 'info',
            });
            return;
        }

        if (isMediumClearable) {
            return;
        }
        setValue('isPhoneTwoFactorEnabled', isEnabled);
    };

    const sendTwoFactorClearCode = async () => {
        await httpClient.post('two-factor/send-delete-code');
        await refreshSettings();
        enqueueSnackbar(t('twoFactor.snackbar.twoFactorClearCodeSent'), {
            variant: 'success',
        });
    };

    const cancelMediumClear = async () => {
        await httpClient.post('two-factor/cancel-delete');
        setMediumClearCode('');
        await refreshSettings();
    };

    const verifyMediumClearCode = async (): Promise<void> => {
        const isVerified = await httpClient.post<boolean>(
            'two-factor/verify-delete-code',
            {
                code: mediumClearCode,
            }
        );
        if (isVerified) {
            enqueueSnackbar(t('twoFactor.snackbar.mediumClearCodeVerifySuccess'), {
                variant: 'success',
            });
            setMediumClearCode('');
        } else {
            enqueueSnackbar(t('twoFactor.snackbar.mediumClearCodeVerifyFailed'), {
                variant: 'error',
            });
        }
        await refreshSettings();
    };

    useEffect(() => {
        void refreshSettings();
    }, []);

    return (
        <Paper
            className={classes.pageContainer}
            style={{ minWidth: 480 }}
            component='form'
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={classes.configSubSection}>
                <Typography variant='h6'>{t('twoFactor.emailTitle')}</Typography>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(e) => {
                                    updateEmailTwoFactor(e.target.checked);
                                }}
                                checked={formValues.isEmailTwoFactorEnabled}
                            />
                        }
                        label={t('twoFactor.form.isEmailTwoFactorEnabled')}
                        name='isEmailTwoFactorEnabled'
                    />
                </div>
                <div>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                classes={inputClasses}
                                value={formValues.email}
                                onChange={handleChangeValue}
                                disabled={!formValues.isEmailTwoFactorEnabled}
                                name='email'
                            />
                        }
                        label={t('twoFactor.form.email')}
                        labelPlacement='top'
                    />
                </div>
            </div>

            <div className={classes.configSubSection}>
                <Typography variant='h6'>{t('twoFactor.phoneTitle')}</Typography>
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(e) => {
                                    updatePhoneTwoFactor(e.target.checked);
                                }}
                                checked={formValues.isPhoneTwoFactorEnabled}
                            />
                        }
                        label={t('twoFactor.form.isPhoneTwoFactorEnabled')}
                        name='isPhoneTwoFactorEnabled'
                    />
                </div>
                <div>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                classes={inputClasses}
                                value={formValues.phoneNumber}
                                onChange={handleChangeValue}
                                disabled={!formValues.isPhoneTwoFactorEnabled}
                                name='phoneNumber'
                            />
                        }
                        label={t('twoFactor.form.phoneNumber')}
                        labelPlacement='top'
                    />
                </div>
            </div>

            {isVerificationCodePending && (
                <div className={classes.configSubSection}>
                    <TwoFactorCodeInput
                        title={t('twoFactor.form.verificationCode')}
                        name='verificationCode'
                        onChange={setVerificationCode}
                        code={verificationCode}
                        onVerify={verifyMedium}
                        onResendCode={resendMediumVerification}
                        verifyText={t('twoFactor.verifyMediumBtn')}
                    />
                </div>
            )}

            {isDeleteVerificationCodePending && (
                <div className={classes.configSubSection}>
                    <TwoFactorCodeInput
                        title={t('twoFactor.form.mediumClearCode')}
                        name='mediumClearCode'
                        onChange={setMediumClearCode}
                        code={mediumClearCode}
                        onVerify={verifyMediumClearCode}
                        onResendCode={sendTwoFactorClearCode}
                        verifyText={t('twoFactor.deleteMediumBtn')}
                        onCancel={cancelMediumClear}
                        cancelText={t('twoFactor.cancelMediumClearCodeBtn')}
                    />
                </div>
            )}

            <div>
                <Button type='submit' variant='contained' className={classes.saveButton}>
                    {t('common:save')}
                </Button>

                {isMediumClearable && !isDeleteVerificationCodePending && (
                    <Button
                        variant='contained'
                        className={classes.saveButton}
                        style={{ marginLeft: '1rem' }}
                        onClick={sendTwoFactorClearCode}
                    >
                        {t('user-management:clearTwoFactor')}
                    </Button>
                )}
            </div>
            <Grid />
        </Paper>
    );
};
